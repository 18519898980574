<template>
  <div class="desks-sidea">
    <!-- <panav :color='color' :bgColor='bgColor' /> -->
    <Navigation :bgColor='bgColor' :color='color' />
    <header>
      <div class="left">
        <img src="~img/20.png" v-if="leftshow" class="left-up" @click="leftshow = false;rightshow = true">
        <img src="~img/19.png" v-if="!leftshow" class="left-down" @click="leftshow = true">
        <div class="left-scroll" v-if="!leftshow">
          <ul>
            <li v-for="item in classList" :key="item.id" :class="{active:item.id == id}" @click="chanPin(item.id)">
              {{item.name}}</li>
          </ul>
        </div>
      </div>
      <div class="right">
        <img src="~img/21.png" v-if="rightshow" class="right-up" @click="rightshow = false; leftshow = true">
        <img src="~img/18.png" v-if="!rightshow" class="right-down" @click="rightshow = true">
        <div class="right-scroll" v-if="!rightshow">
          <ul>
            <li v-for="item in artistList" :key="item.id" :class="{active:item.id == id}" @click="yiShu(item.id)">
              {{item.name}}</li>
          </ul>
        </div>
      </div>
    </header>
    <main>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <div v-for="(item,index) in list" :key="index">
          <img :src="item.image" alt="图片地址加载错误" @click="Jump(item.url)" />
        </div>
      </van-list>
      <transition name="el-fade-in-linear">
        <img src="~img/39.svg" class="logo" v-show="showLogo">
      </transition>
      <p class="beian">© 2022 富富 SIDE PROJECT</p>
    </main>
    <horserace :color='noticeColor' :background='background' />
  </div>
</template>
<script>
import Navigation from "@/components/ipad/Navigation.vue";
import horserace from "@/components/pc/horserace.vue";
export default {
  components: {
    Navigation,
    horserace,
  },
  data() {
    return {
      list: [],
      color: "#ff7030",
      leftshow: true,
      rightshow: true,
      classList: [], // 产品分类列表
      artistList: [], //艺术家分类
      noticeColor: "#fff",
      background: "#ff7030",
      bgColor: "#fff",
      loading: false,
      finished: false,
      page: 0,
      classify_id: "",
      artist_id: "",
      id: "",
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
      showLogo: false,
    };
  },
  created() {
    this.id = this.getQueryValue("id");
    this.getclassify(); // 产品分类
    this.getartist(); // 艺术家分类
    this.handleScroll();
  },
  watch: {
    scrollTop(newValue, oldValue) {
      setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          console.log("滚动结束");
          this.oldScrollTop = newValue; //每次滚动结束后都要给oldScrollTop赋值
          this.showLogo = false;
        }
      }, 300); //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        console.log("滚动开始");
        this.showLogo = true;
      }
    },
  },
  methods: {
    handleScroll() {
      window.addEventListener("scroll", () => {
        this.scrollTop = window.scrollY;
      });
    },
    // 点击产品进行跳转
    Jump(url) {
      location.href = url;
    },
    // 获取产品列表
    async getclassify() {
      const res = await this.axios.get("/api/index/classify");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.classList = data;
        this.classify_id = this.classList[0].id;
        // this.chanPin(this.classList[0].id);
      }
    },
    // 获取艺术家列表
    async getartist() {
      const res = await this.axios.get("/api/index/artist");
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.artistList = data;
      }
    },
    // 点击产品获取对于产品数据
    chanPin(id) {
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.classify_id = id;
      this.artist_id = "";
      this.id = id;
      this.getProduct(id, "");
      this.$router.push({ path: `desksidea?id=${id}&name=chanpin` });
    },
    // 获取艺术家数据
    yiShu(id) {
      this.page = 1;
      this.list = [];
      this.finished = false;
      this.classify_id = "";
      this.artist_id = id;
      this.id = id;
      this.getProduct("", id);
      this.$router.push({ path: `desksidea?id=${id}&name=yishu` });
    },
    // 页面一进来加载数据
    onLoad() {
      this.page++;
      let name = this.getQueryValue("name");
      if (this.id) {
        if (name == "chanpin") {
          this.getProduct(this.id, "");
        } else {
          this.getProduct("", this.id);
        }
      } else {
        this.getProduct(this.classify_id, this.artist_id);
      }
    },
    // 根据产品id  或者 艺术家id 获取对于产品数据
    //  classify_id 产品id
    //  artist_id  艺术家id
    async getProduct(classify_id, artist_id) {
      const res = await this.axios.post(
        `/api/index/product?page=${this.page}`,
        {
          classify_id: classify_id,
          artist_id: artist_id,
        }
      );
      if (res.status == 200 && res.data.code == 1) {
        const { data } = res.data;
        this.loading = false;
        if (data.data.length > 0) {
          data.data.forEach((item) => {
            this.list.push(item);
          });
        } else {
          this.finished = true;
        }
        this.leftshow = true;
        this.rightshow = true;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.desks-sidea {
  header {
    position: sticky;
    top: 90px;
    display: flex;
    .left {
      width: 50%;
      position: relative;
      height: 75px /* 50/100 */;
      .left-up {
        position: absolute;
        left: 0.75rem /* 32/100 */;
        cursor: pointer;
        z-index: 9;
      }
      .left-down {
        position: absolute;
        left: 0.75rem /* 32/100 */;
        bottom: 0;
        cursor: pointer;
        z-index: 9;
      }
      .left-scroll {
        width: 480px;
        background: #a8f796;
        border-radius: 15px /* 15/100 */;
        position: absolute;
        left: 59px /* 20/100 */;
        top: 25px /* 25/100 */;
        z-index: 8;
        // transform: rotateY(45deg);
        transform: rotateZ(-5deg);
        padding-bottom: 20px /* 20/100 */;
        ul {
          padding-top: 50px /* 50/100 */;
          padding-left: 20px /* 20/100 */;
          li {
            font-size: 30px; /* 26/100 */
            font-weight: bold;
            color: #000;
            margin-bottom: 10px /* 10/100 */;
            cursor: pointer;
            transition: 0.2s all;
            font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
          }
          li:hover {
            color: #fff;
          }
          .active {
            color: #fff;
          }
        }
      }
    }
    .right {
      width: 50%;
      height: 75px;
      position: relative;
      .right-up {
        position: absolute;
        right: 0.75rem /* 32/100 */;
        cursor: pointer;
        z-index: 9;
      }
      .right-down {
        position: absolute;
        right: 0.75rem /* 32/100 */;
        bottom: 0;
        cursor: pointer;
        z-index: 9;
      }
      .right-scroll {
        width: 480px;
        background: #ffd7d6;
        border-radius: 0.15rem /* 15/100 */;
        position: absolute;
        right: 50px /* 20/100 */;
        top: 25px /* 25/100 */;
        z-index: 8;
        transform: rotateZ(5deg);
        padding-bottom: 20px /* 20/100 */;
        ul {
          padding-top: 50px /* 50/100 */;
          padding-left: 20px /* 20/100 */;
          li {
            font-size: 30px; /* 26/100 */
            font-weight: bold;
            color: #000;
            margin-bottom: 10px /* 10/100 */;
            cursor: pointer;
            transition: 0.2s all;
            font-family: PHANBO, GLEXBL; //导航栏、筛选模块、底部跑马灯字体
          }
          li:hover {
            color: #fff;
          }
          .active {
            color: #fff;
          }
        }
      }
    }
  }
  main {
    margin-top: 0.5rem /* 50/100 */;
    padding-bottom: 1rem;
    padding-left: 55px;
    padding-right: 55px;
    p {
      text-align: center;
      font-size: 12px;
      color: #ff7030;
    }
    .van-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      div {
        width: 45%;
        margin-bottom: 15px;
        img {
          width: 100%;
          display: block;
        }
      }
    }
    .logo {
      width: 90%;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  /deep/.vue-waterfall {
    max-width: 100%;
    img {
      width: 100%;
    }
    .vue-waterfall-column {
      width: 45% !important;
      margin-right: 2.5%;
      margin-left: 2.5%;
      img {
        margin-bottom: 0.2rem;
      }
    }
  }
  .notice {
    height: 50px;
    line-height: 50px;
    font-size: 24px;
  }
}
</style>
